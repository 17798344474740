import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logger } from "../Logger/logger";
import { useLocation } from "react-router-dom";
import {
    fetchUserRuns,
    handleEvent
} from "../Services/Cashkaro/Cashkaro";

export const Home = () => {
    const navigate = useNavigate();

    const [user, setUser] = useState({});

    const [userRuns, setUserRuns] = useState(0);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();

    useEffect(() => {
        if (query.get("name") && query.get("uid")) {
            localStorage.setItem("name", query.get("name"));
            localStorage.setItem("uid", query.get("uid"));
            setUser({ ...user, name: query.get("name"), uid: query.get("uid") });
        }
        else navigate("/error");
    }, []);

    const [didUserAchivedAlready, setDidUserAchievedAlready] = useState(true);

    useEffect(() => {

        if (userRuns) {

            if (userRuns < 100 && userRuns > 0) {
                setDidUserAchievedAlready(false);
            } else {

                if (!didUserAchivedAlready) {

                    // handleConfette();

                }
            }
        }

    }, [userRuns]);

    const [pending1, setPending1] = useState(false);
    const [pending2, setPending2] = useState(false);
    const [pending3, setPending3] = useState(false);
    const [pending4, setPending4] = useState(false);
    const [pending5, setPending5] = useState(false);

    useEffect(() => {

        if (user && user.name && user.uid) {
            handleEvent({ event: "checkIn" }, user, setPending5, pending5);
        }

    }, [user]);




    const handleMessage = async (e) => {
        // Handle the received message here
        const event = e.data;

        let _event = event.event;

        if (_event) {

            console.log(`event triggered`, _event)

            if (_event == "gameOver" ||
                _event == "gameStart" ||
                _event == "restart" || _event == "hitRun" ||
                _event == "gotoLeaderboard" ||
                _event == "requestFullScreen" ||
                _event == "logout" ||
                _event == "exitFullScreen"
            ) {

                logger("info", {
                    tag: event.event,
                    data: {
                        event,
                        session_id: localStorage.getItem("session_id"),
                        game_session_id: localStorage.getItem("game_session_id"),
                        user: { name: query.get("name"), uid: query.get("uid") },
                    },
                });
            }

            if (event.event == "gameOver") {
                await handleEvent(event, {
                    name: query.get("name"),
                    uid: query.get("uid"),
                }, setPending1, pending1);
            }

            if (event.event == "gameStart") {
                await handleEvent(event, {
                    name: query.get("name"),
                    uid: query.get("uid"),
                }, setPending2, pending2);
            }

            if (event.event == "restart") {
                await handleEvent(event, {
                    name: query.get("name"),
                    uid: query.get("uid"),
                }, setPending3, pending3);
            }

            if (event.event == "hitRun") {
                await handleEvent(event, {
                    name: query.get("name"),
                    uid: query.get("uid"),
                }, setPending4, pending4);

                let updatedRuns = userRuns + parseInt(event.score);
                setUserRuns(updatedRuns);
            }

            if (event.event == "gotoLeaderboard") {
                event.event = "button";
                event.value = "leaderboard";
                navigate(
                    "/leaderboard?name=" + query.get("name") + "&uid=" + query.get("uid")
                );
            }

            if (event.event == "requestFullScreen") {
                requestFullScreen();
            }

            if (event.event == "exitFullScreen") {
                exitFullScreen();
            }

            if (event.event == "logout") {
                logoutUser();
            }

        }
    };

    // Add the event listener
    useEffect(() => {

        const interval = setTimeout(() => {
            window.addEventListener("message", handleMessage);
        }, 1000);

        return () => {
            clearInterval(interval);
            window.removeEventListener("message", handleMessage);
        };

    }, [])

    useEffect(() => {
        document.addEventListener("fullscreenchange", function () {
            if (document.fullscreenElement === null) {
                // Full screen was exited, and you can handle it here.
                // You can perform any necessary actions here when full screen is exited.
            }
        });

        // You can also check for vendor-prefixed versions of the event
        document.addEventListener("webkitfullscreenchange", function () {
            if (document.webkitFullscreenElement === null) {
                console.log("Full screen exited in WebKit!");
            }
        });

        document.addEventListener("mozfullscreenchange", function () {
            if (document.mozFullScreenElement === null) {
                console.log("Full screen exited in Mozilla!");
            }
        });

        document.addEventListener("msfullscreenchange", function () {
            if (document.msFullscreenElement === null) {
                console.log("Full screen exited in Microsoft Edge!");
            }
        });
    }, [userRuns]);

    const logoutUser = () => {
        localStorage.removeItem("authToken");
        navigate("/login");
    };

    const requestFullScreen = () => {
        try {
            const iframe = document.querySelector(".mc-iframe-game");

            // Check if the document is already in fullscreen
            if (
                document.fullscreenElement ||
                document.mozFullScreenElement ||
                document.webkitFullscreenElement ||
                document.msFullscreenElement
            ) {
                // An element is already in fullscreen mode, do not execute the request again
                console.log("Already in fullscreen.");
                exitFullScreen();
            } else {
                localStorage.setItem("screen", "requestedFull");

                // Check if the iframe supports fullscreen
                if (iframe.requestFullscreen) {
                    iframe.requestFullscreen();
                } else if (iframe.mozRequestFullScreen) {
                    // Firefox
                    iframe.mozRequestFullScreen();
                } else if (iframe.webkitRequestFullscreen) {
                    // Chrome, Safari, and Opera
                    iframe.webkitRequestFullscreen();
                } else if (iframe.msRequestFullscreen) {
                    // IE/Edge
                    iframe.msRequestFullscreen();
                } else if (iframe.webkitEnterFullscreen) {
                    iframe.webkitEnterFullscreen(); //for iphone this code worked
                } else {
                    console.error("Fullscreen API is not supported in this browser.");
                }
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    const exitFullScreen = () => {
        try {
            const iframe = document.querySelector(".mc-iframe-game");

            console.log("I am logging");
            localStorage.setItem("screen", "requestedOut");

            if (
                document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullscreenElement
            ) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    // Firefox
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    // Chrome, Safari, and Opera
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    // IE/Edge
                    document.msExitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen(); //for iphone this code worked
                }
            }

            if (iframe.exitFullscreen) {
                iframe.exitFullscreen();
            } else if (iframe.mozCancelFullScreen) {
                // Firefox
                iframe.mozCancelFullScreen();
            } else if (iframe.webkitExitFullscreen) {
                // Chrome, Safari, and Opera
                iframe.webkitExitFullscreen();
            } else if (iframe.msExitFullscreen) {
                // IE/Edge
                iframe.msExitFullscreen();
            } else if (iframe.webkitExitFullscreen) {
                iframe.webkitExitFullscreen(); //for iphone this code worked
            }
        } catch (err) {
            console.log(err.message);
        }
    };


    const handleUserRuns = async () => {
        const res = await fetchUserRuns(query.get("uid"));
        if (res && res.status === 200) {
            setUserRuns(parseInt(res.data?.data?.total_runs || 0));
        }
    }


    useEffect(() => {
        const iframe = document.querySelector(".mc-iframe-game");
        const loader = document.querySelector(".loader");

        iframe.addEventListener("load", () => {
            // Hide the loader when the iframe's content has finished loading.
            loader.style.display = "none";
        });


        handleUserRuns();

    }, []);


    return (
        <div className="mc-iframe-wrapper">
            <div class="iframe-container">
                <div class="loader"><img src="/images/paDDINGUP-ezgif.com-video-to-gif-converter.gif"></img></div>

                <iframe
                    className="mc-iframe-game"
                    src={`https://almondvirtex.s3.ap-south-1.amazonaws.com/cashkaro_game_v2.0/index.html`}
                ></iframe>
                {/* <iframe className="mc-iframe-game" src={`cashkaro_game_v1.5/index.html`}></iframe> */}
            </div>



            <style>
                {`
            html, body {
                margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
    }

            .App{
                background:black;
            }

            .iframe-container {
                width: 100vh; /* Set the width of the container to match the height of the viewport */
            height: 100vw; /* Set the height of the container to match the width of the viewport */
            overflow: hidden; /* Hide any overflowing content */
    }

            .mc-iframe-game {
                width: 100vh;
            height: 100vw;
            transform-origin: top left; /* Set the origin of the rotation */
            transform: rotate(90deg); /* Rotate the iframe counterclockwise by 90 degrees */
            position: absolute;
            left: 100%; /* Position the iframe to the right edge of the container */
            top: 0;
    }`}
            </style>
        </div>
    );
};
