import { useEffect, useState } from "react";
import { getLeaderBoardScores } from "../Services/Score/Scores";
import { baseurl } from "../constants";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {
  handleEvent,
} from "../Services/Cashkaro/Cashkaro";

export default function Leaderboard() {
  const navigate = useNavigate();

  const [leaderBoardType, setLeaderBoardType] = useState("daily");

  const [rankings, setRankings] = useState([]);

  const [currentRank, setCurrentRank] = useState([]);

  const [page, setPage] = useState(1);

  const [showMore, setShowMore] = useState(true);
  const [toggleImg, setToggleImg] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const getScore = async (e) => {
    try {
      const response = await axios.get(
        `${baseurl}/api/user/ranking?type=${leaderBoardType}&uid=${query.get(
          "uid"
        )}&page=${page}&limit=50`
      );

      console.log({ response });

      if (response.data.success) {
        if (response.data.data.rankings.length < 10) {
          setShowMore(false);
        } else {
          // setShowMore(true);
        }

        // name, score, rank

        let curr_rank = response.data.data.your_rank || [];
        setCurrentRank(curr_rank);
        let curr_rankings = response.data.data.rankings || [];
        setRankings(curr_rank.concat(curr_rankings));
      }
    } catch (err) {
      return err.message;
    }
  };

  useEffect(() => {
    if (toggleImg) {
      setLeaderBoardType("daily")
    } else {
      setLeaderBoardType("monthly")
    }

    getScore();
  }, [toggleImg]);

  useEffect(() => {
    // const handleBeforeUnload = async (event) => {
    //   // Optional: To prevent the default behavior of the beforeunload event
    //   event.preventDefault();

    //   // Make the API call
    //   await handleEvent({ event: "CheckOut" }, {name: query.get("name"), uid: query.get("uid")});
    // };

    // window.addEventListener('beforeunload', handleBeforeUnload);

    // // Cleanup the event listener on component unmount
    // return () => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // };
  }, []);

  return (
    <div className="mc-leaderboard-container">
      <div className="mc-leaderboard-wrapper">
        <img
          src="/images/leaderboard1 1.png"
          className="mc-leaderboard-image"
          alt=""
        />
        <div className="mc-score-container ">
          {/* <div className="mc-score-row mc-first-one">
                    <span className="mc-name">{userName}</span>
                    <span className="mc-score">{currRank?.highest_score || ""}</span>
                    <span className="mc-rank">{currRank?.ranking - 1 || "__"}</span>
                </div> */}
          <div className="mc-score-row mc-first-one">
            {/* <span className={`mc-name ${activeTab === 0 ? "mc-active-tab" : ""}`} onClick={() => { setActiveTab(0) }}>
                        <button type="button" className="mc-btn daily-btn">Daily</button>
                    </span> */}
            {/* <div className="mc-week-container mc-btn weekly-btn">
              <select
                className="mc-btn"
                onChange={(e) => {
                  setLeaderBoardType(e.target.value);
                }}
              >
                <option value={"daily"}>Daily</option>
                <option value={"monthly"}>Monthly</option>
              </select>
            </div> */}

            <div className="leaderboardBtn">
              <img
                src="/images/LeaderboardBtn.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="toggleBtn">
              {toggleImg ? (
                <img
                  src="/images/Total.png"
                  alt=""
                  className="img-fluid"
                  onClick={() => setToggleImg(!toggleImg)}
                />
              ) : (
                <img
                  src="/images/Today.png"
                  alt=""
                  className="img-fluid"
                  onClick={() => setToggleImg(!toggleImg)}
                />
              )}
            </div>
          </div>
          <div className="score_players">
            <h3 className="text-uppercase">Player</h3>
            <h3 className="text-uppercase leaderboard_score">Score</h3>
            <h3 className="text-uppercase">Rank</h3>
          </div>
          <div className="scroll_leaderboard">
            {rankings &&
              rankings.map((item, index) => {
                return (
                  <div className={currentRank.length > 0 && index == 0 ? 'self_ranking mc-score-row' : 'mc-score-row'}>
                    <span className="mc-name">{currentRank.length > 0 && index == 0 ? `You (${item.name})` : item.name}</span>
                    <span className="mc-score">{item.score}</span>
                    <span className="mc-rank">{item.globalRank}</span>
                  </div>
                );
              })}
          </div>

          {/* {showMore && (
            <div className="mc-score-row">
              <span
                className="mc-score"
                style={{ margin: "0 auto", cursor: "pointer" }}
                onClick={() => {
                  setPage(page + 1);
                }}
              >{`Show More`}</span>
            </div>
           )}  */}
        </div>
      </div>
      <button
        className="mc-btn mc-back-btn"
        onClick={() => {
          navigate(
            `/?name=${localStorage.getItem("name")}&uid=${localStorage.getItem(
              "uid"
            )}`
          );
          // window.location.reload();
        }}
        onTouchStart={() => {
          navigate(
            `/?name=${localStorage.getItem("name")}&uid=${localStorage.getItem(
              "uid"
            )}`
          );
          // window.location.reload();
        }}
      >
        Back
      </button>
    </div>
  );
}
