import axios from "axios";
import { baseurl } from "../../constants";
import { logger } from "../../Logger/logger";

export const handleEvent = async (event, user, setPending, pending) => {
    try {

        if (!pending) {

            setPending(true);

            const response = await axios.post(`${baseurl}/api/user/event`, {
                event,
                session_id: localStorage.getItem("session_id") || "",
                game_session_id: localStorage.getItem("game_session_id") || "",
                name: user.name,
                uid: user.uid
            });

            setPending(false);

            if (response.status === 200) {
                if (response.data.data.session_id) {
                    localStorage.setItem("session_id", response.data.data.session_id);
                }

                if (response.data.data.game_session_id) {
                    localStorage.setItem("game_session_id", response.data.data.game_session_id);
                }
            }
            return true;

        }

    } catch (err) {

        setPending(false);
        return err.message;
    }
}


export const fetchUserRuns = async (uid) => {
    try {

        const response = await axios.get(`${baseurl}/api/user/runs/${uid}`);

        return response;

    } catch (err) {
        return err.message;
    }
}